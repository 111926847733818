import React, { useEffect } from 'react'
import SEO from '../components/SEO'
import Footer from '../components/footer'
import Header from '../components/header'
import { display } from '../utils'
const loadText = require('src/utils').loadText
const data = loadText('documentation')
import '../styles/documentation.less'
const contentModify = (title, content) => {
  let newContent = content
  if (title === 'Cookie Policy') {
    newContent += '<div id="ot-sdk-cookie-policy"></div>'
  }
  if (title === 'Privacy Policy') {
    newContent = newContent.replace(
      '<strong>Your California Privacy Rights</strong>',
      "<div id='ccpa' style='position: relative; bottom: 70px; height:1px;'></div><strong>Your California Privacy Rights</strong>"
    )
  }
  return newContent
}
const nameToDate = {
  'California Privacy Policy': 'January 1, 2023',
}
const seoObj = {
  '亚马逊云科技 APN（AWS Partner Network）标准技术合作伙伴': {
    title: '亚马逊云科技 - APN（AWS Partner Network）-标准技术合作伙伴',
    keywords: ['云连接', '混合云'],
    description:
      '2022 年 11 月， Zenlayer 荣获亚马逊云科技“2022 年度 ISV 合作伙伴”和“2022 年度 Marketplace 合作伙伴”两项大奖！这再次彰显了Zenlayer 在助力客户创新及在与亚马逊云科技提供联合解决方案方面发挥着重要作用。',
  },
}
const Documentation = ({ pageContext }) => {
  const { title, content, created_at, updated_at } = pageContext
  useEffect(() => {
    if ((window as any).OneTrust) {
      ;(window as any).OneTrust.initializeCookiePolicyHtml()
    }
  }, [])
  const seo = seoObj[title] || {}
  const seoTitle = seo.title || `${title} | Zenlayer`
  const seoDescription = seo.description || title
  const seokeywords = seo.keywords || []
  return (
    <div className="documentation">
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={seokeywords}
        noIndex={title === 'ZGA APP Account Cancellation Policy'}
      />
      <Header logo="/logo-blue.svg" />
      <div className="documentation-title">
        <div className="font-36 semi-bold t-color">{title}</div>
        <div className="mt-16 sub">
          {`${data.subject.updated_at}: ${
            nameToDate[title] || display(updated_at)
          }`}
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: contentModify(title, content),
        }}
        className="documentation-content"
      />
      <Footer />
    </div>
  )
}

export default Documentation
